import { fetchAndActivate, getValue } from 'firebase/remote-config';
import { remoteConfig } from 'plugins/firebase/firebase';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ERemoteConfigParameter } from './remoteConfigService.constants';
import { IRemoteConfigContext, TParameter } from './remoteConfigService.type';

export const RemoteConfigContext = createContext<IRemoteConfigContext>({
  parameter: {} as TParameter,
});

export const RemoteConfigProvider = ({ children }: { children: ReactNode }) => {
  const [parameter, setParameter] = useState<TParameter>({} as TParameter);
  const [isEndFetching, setIsEndFetching] = useState<boolean>(false);
  const remoteConfigParamKeys = Object.keys(ERemoteConfigParameter);

  /**
   * 取得 remote config 的參數
   */
  const getParameters = () => {
    fetchAndActivate(remoteConfig)
      .then(() => {
        const params = remoteConfigParamKeys.reduce<TParameter>((prev, key) => {
          const param = getValue(remoteConfig, key).asBoolean();
          const value = param;
          return {
            ...prev,
            [key]: value,
          };
        }, {} as TParameter);
        setParameter(params);
        setIsEndFetching(true);
      })
      // eslint-disable-next-line no-console
      .catch(error => console.log(error));
  };

  useEffect(() => {
    getParameters();
  }, []);

  console.error({ isEndFetching });
  // 確保 remote config 取得後才 render children
  // if (!isEndFetching) return null;

  return (
    <RemoteConfigContext.Provider value={{ parameter }}>
      {children}
    </RemoteConfigContext.Provider>
  );
};

export const useRemoteConfigService = () => useContext(RemoteConfigContext);
